import styled from "styled-components"
import media from "utils/media"

export const BoardContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 4rem;
`
export const BoardHeaderText = styled.h3`
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 54px;
	line-height: 107.5%;
	letter-spacing: -0.04em;
	color: #6d0062;
	text-align: center;
	${media.mobile`
	font-size: 27px;
	`}
`
export const BoardDescText = styled.p`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
	text-align: center;
	margin-top: 15px;
	${media.mobile`
	font-size: 14px;
	font-weight: 400;
	`}
`

export const BoardDiv = styled.div`
	width: 80%;
	display: flex;
	justify-content: space-around;
	margin: 0 auto;
	gap:10px;
	margin-top: 4rem;
	margin-bottom: 3rem;
	${media.mobile`
	flex-direction: column;
	width: 75%;
	`}
`
export const BoardCard = styled.div`
	width: 25%;
	display: flex;
	flex-direction: column;
	.title-board {
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 20px;
		margin-top: 6px;
		color: #000000;
	}
	.role-board {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 20px;
		letter-spacing: 0.025em;
		text-transform: capitalize;
		color: #6d0062;
	}
	${media.mobile`
	flex-direction: column;
	width: 100%;
	margin-bottom:2.5rem;
	`}
`
export const BoardCardImage = styled.div`
	height: 342px;
	width: 100%;
	border: 1px solid #12aa12;
	background: linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%);
	border-radius: 56px;
	img {
		width: 100%;
		height: 342px;
		border-radius: 56px;
	}
`
