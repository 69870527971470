import { Img } from "globalStyles"
import React from "react"
import boardData from "./boardData"
import {  BoardCardImage, BoardContainer, BoardDescText, BoardHeaderText } from "./boardStyle"

function Board() {
	return (
		<BoardContainer>
			<div>
				<BoardHeaderText>Meet the Executive Board</BoardHeaderText>
				<BoardDescText>A dedicated team committed to extraordinary results</BoardDescText>
			</div>
			<div className="grid md:grid-cols-4 font-poppins my-5 gap-4 max-w-6xl mx-auto px-4">
				{boardData?.map(member => (
					<div key={member.id}>
						<BoardCardImage>{member.imgUrl ? <Img src={member.imgUrl} alt={member.name} className="object-cover w-full" /> : null}</BoardCardImage>
						<p className="font-bold font-poppins">{member.title}</p>
						<p className="title-board text-lg font-poppins">{member.name}</p>
						<p className="font-bold text-xl font-poppins">{member.role}</p>
					</div>
				))}
			</div>
		</BoardContainer>
	)
}

export default Board
